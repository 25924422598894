import { lazy } from "react"
import { Navigate } from "react-router-dom"
import { Loadable } from './utils'

const Dashboard = Loadable(lazy(() => import("../pages/Dashboard")))
const Bot = Loadable(lazy(() => import("../pages/Bot")))
const Strategies = Loadable(lazy(() => import("../pages/Strategies")))
const Reports = Loadable(lazy(() => import("../pages/Reports")))
const Help = Loadable(lazy(() => import("../pages/Help")))
const Settings = Loadable(lazy(() => import("../pages/Settings")))
const Calculator = Loadable(lazy(() => import("../pages/Calculator")))
const LoginBinary = Loadable(lazy(() => import("../pages/LoginBinary")))
const RecoverFirst = Loadable(lazy(() => import("../pages/authentication/RecoverFirst")))

/*****Routes******/
export const InternalRouter = [
  {
    path: "/",
    element: <Navigate to="/dashboard" />,
  },
  {
    path: "/binary",
    element: <LoginBinary />,
  },
  {
    path: "recover/",
    element: <RecoverFirst />,
  },
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "strategies",
    element: <Strategies />,
  },
  {
    path: "bot",
    element: <Bot />,
  },
  {
    path: "calculator",
    element: <Calculator />,
  },
  {
    path: "reports",
    element: <Reports />,
  },
  {
    path: "help",
    element: <Help />,
  },
  {
    path: "settings",
    element: <Settings />,
  },
  {
    path: "*",
    element: <Navigate to="/dashboard" />,
  }
]
