import { useEffect, useState } from 'react'
import { CssBaseline, ThemeProvider } from "@mui/material"
import StyledEngineProvider from "@mui/material/StyledEngineProvider"
import {
  BrowserRouter as Router,
  useRoutes
} from "react-router-dom"
import { AuthRouter, InternalRouter } from './routes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { createCustomTheme } from './theme'
import useSettings from "./hooks/useSettings"
import BotProvider from './contexts/BotContext'
import BinaryProvider from './contexts/BinaryContext'
import toast, { Toaster, useToasterStore } from 'react-hot-toast'

const getRoutes = () => {
  const authenticated = Boolean(localStorage.getItem('accessToken'))
  return (authenticated) ? InternalRouter : AuthRouter
}

const App = () => {
  let routes = useRoutes(getRoutes())
  return routes;
};

const AppWrapper = () => {
  const { settings } = useSettings()
  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
  })
  const { toasts } = useToasterStore();

  const TOAST_LIMIT = 5

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);
  
  return (
    <StyledEngineProvider injectFirst>
      <BinaryProvider>
        <BotProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
              <Toaster/>
              <App />
            </Router>
            <ToastContainer />
          </ThemeProvider>
        </BotProvider>
      </BinaryProvider>
    </StyledEngineProvider>
  );
};
export default AppWrapper;
