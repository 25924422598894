import { useState, createContext, useEffect } from "react"
import _Blockly from '../pages/layout/blockly'
import {
  setTokenList,
  getTokenList
} from '../services/storage'
import { symbolPromise } from '../pages/layout/shared'
import { updateConfigCurrencies } from '../pages/common/const'
import { load } from "../pages/layout/blockly"
import * as api from '../services/api'
import { checkForRequiredBlocks } from '../contexts/bot'
import { observer as globalObserver } from '../common/utils/observer';
import { toast } from 'react-hot-toast'
import { translate } from '../common/i18n';
import { roundBalance } from '../pages/common/tools';

globalObserver.register('Notify', info => {
  toast.success(info.message, {
    position: 'bottom left',
    autoHide: false,
    className: 'warn web-status',
  })
});

globalObserver.register('Error', (error) => {
  if (!error) {
    return;
  }

  let message;
  let code;

  if (typeof error === 'string') {
    code = 'Unknown';
    message = error;
  } else if (error.error) {
    if (error.error.error) {
      ({ message } = error.error.error);
      ({ code } = error.error.error);
    } else {
      ({ message } = error.error);
      ({ code } = error.error);
    }
  } else {
    ({ message } = error);
    ({ code } = error);
  }

  // Exceptions:
  if (message === 'Cannot read property \'open_time\' of undefined') {
    // SmartCharts error workaround, don't log nor show.
    return;
  }
  toast.error(message, {
    position: 'bottom right',
    autoHide: false,
    className: 'warn web-status',
  })
});

const isNumber = num => num !== '' && Number.isFinite(Number(num));

const getProfit = ({ sell_price: sellPrice, buy_price: buyPrice, currency }) => {
  if (isNumber(sellPrice) && isNumber(buyPrice)) {
    return roundBalance({
      currency,
      balance: Number(sellPrice) - Number(buyPrice),
    });
  }
  return '';
};

const getTimestamp = date => {
  const buyDate = new Date(date * 1000);
  return `${buyDate.toISOString().split('T')[0]} ${buyDate.toTimeString().slice(0, 8)} ${buyDate.toTimeString().split(' ')[1]
    }`;
};

const getTradeObject = (contract) => {
  const tradeObj = {
    ...contract,
    reference: `${contract.transaction_ids.buy}`,
    buy_price: roundBalance({ balance: contract.buy_price, currency: contract.currency }),
    timestamp: getTimestamp(contract.date_start)
  };

  if (contract.entry_tick) {
    tradeObj.entry_tick = contract.entry_spot_display_value;
  }

  if (contract.exit_tick) {
    tradeObj.exit_tick = contract.exit_tick_display_value;
  }

  return tradeObj;
}

export const BotContext = createContext()

const BotProvider = ({ children }) => {
  const [bot, setBot] = useState({})
  const [bots, setBots] = useState([])
  const [blockly, setBlockly] = useState()
  const [isConnected, setConnected] = useState(false)
  const [botRunning, setBotRunning] = useState(false)
  const [activeAccount, setActiveAccount] = useState({})
  const [botStarted, setBotStarted] = useState(false)
  const [xml, setXml] = useState(localStorage.previousStrat)
  const [trades, setTrades] = useState([])
  const [balance, setBalance] = useState({})
  const [isLoadingXml, setIsloadingXml] = useState(false)
  const [statusBar, setStatusBar] = useState(0)
  const [userInfo, setUser] = useState({})
  const [isAuthenticated, setAuthenticated] = useState(false)
  const [visible, setVisible] = useState(false)
  const [summary, setSummary] = useState({})

  function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  globalObserver.register('bot.running', info => {
    setStatusBar(0)
    setBotRunning(true)
  })

  globalObserver.register('bot.stop', info => {
    setBotRunning(false)
    setStatusBar(0)
  })

  globalObserver.register('Error', error => {
    setBotRunning(false)
    setStatusBar(0)
    blockly.stop()
  })
  const makeSummary = () => {
    let totalQuantity = 0
    let onlyProfit = 0
    let profit = 0
    let quantity = 0
    let gains = 0
    let losses = 0
    trades.map(item => {
      if (item.profit) {
        profit += Number(item.profit)
        quantity += Number(item.buy_price)
        totalQuantity += quantity
        if (item.profit > 0) {
          onlyProfit += Number(item.profit)
          gains += 1
        } else {
          losses += 1
        }
      }
    })
    setSummary({
      totalProfit: profit,
      total: trades.length,
      totalWin: gains,
      totalLoss: losses,
      totalQuantity: totalQuantity,
      onlyProfit: onlyProfit
    })
  }

  useEffect(() => {
    setConnected(getTokenList().length > 0)
    api.getBots().then(data => {
      const activeUsers = randomIntFromInterval(100, 1000)
      setBots(data?.scripts?.sort((a, b) => a.stars > b.stars).map((item, index) => {
        item.users = activeUsers
        return item
      }))
      if (localStorage.lastBot) {
        const bt = JSON.parse(localStorage.lastBot || '{}')
        setBot(bt)
        api.getXml(bt.id).then(data => {
          setXml(data)
        })
      }
    })
    globalObserver.register('bot.contract', contract => {
      if (!contract) {
        return;
      }
      const tradeObj = getTradeObject(contract)
      const trade = {
        ...tradeObj,
        profit: getProfit(tradeObj),
        contract_status: translate('Pending'),
        contract_settled: false,
      }
      if (trade.is_expired && trade.is_sold && !trade.exit_tick) {
        trade.exit_tick = '-';
      }
      setTrades(trades => {
        const before = (trades.filter((item) => item.contract_id == trade.contract_id).length > 0) ? trades.filter((item) => item.contract_id == trade.contract_id)[0] : null
        let newTrade;
        if (before) {
          delete trade.timestamp
          newTrade = {
            ...before,
            ...trade
          }
        } else {
          newTrade = trade
        }
        return [...trades.filter((item) => item.contract_id != trade.contract_id), newTrade].sort((a, b) => b.contract_id - a.contract_id)
      })
    })
    makeSummary()
  }, [])

  useEffect(() => {
    makeSummary()
  }, [trades])

  let loaded = false
  useEffect(() => {
    if (!blockly && !loaded) {
      loaded = true
      updateConfigCurrencies().then(() => {
        symbolPromise.then(() => {
          setBlockly(new _Blockly())
        });
      })
    }
  }, [bot])

  useEffect(() => {
    if (botRunning) {
      api.log(bot?.id)
    }
  }, [botRunning])
  const botRun = () => {
    // // setTimeout is needed to ensure correct event sequence
    if (!checkForRequiredBlocks()) {
      setTimeout(() => setBotRunning(false))
      return;
    }
    // initRealityCheck(() => setBotRunning(false))

    setTimeout(() => {
      startBot({})
    }, 2000)
  }

  const selectBot = (bot, exec = false) => {
    if (botRunning) {
      blockly.stop()
      setBotRunning(false)
    }
    try {
      // eslint-disable-next-line no-undef
      Blockly.mainWorkspace.clear()
    } catch { }
    const promise = api.getXml(bot?.id)
      .then(xml => {
        load(xml);
      }).then(() => {
        if (exec) {
          botRun()
          setBotRunning(true)
        }
        return true
      })

    toast.promise(promise, {
      loading: 'Robô carregando aguarde...',
      success: 'Robô carregado com sucesso',
      error: err => 'Erro ao carregar robô: ' + err,
    });

    setBot(bot)
    localStorage.setItem('lastBot', JSON.stringify(bot))
  }
  const startBot = limitations => {
    blockly.run(limitations);
  }

  return (
    <BotContext.Provider
      value={{
        bot: bot,
        setBot: setBot,
        setTokenList: setTokenList,
        isConnected: isConnected,
        startBot: startBot,
        botRunning: botRunning,
        setBotRunning: setBotRunning,
        setActiveAccount: setActiveAccount,
        activeAccount: activeAccount,
        blockly: blockly,
        setBotStarted: setBotStarted,
        botStarted: botStarted,
        selectBot: selectBot,
        botRun: startBot,
        setBots: setBots,
        bots: bots,
        trades: trades,
        setTrades: setTrades,
        balance: balance,
        setBalance: setBalance,
        xml: xml,
        setXml: setXml,
        load: load,
        statusBar: statusBar,
        setStatusBar: setStatusBar,
        setConnected: setConnected,
        setAuthenticated,
        isAuthenticated,
        visible,
        setVisible,
        summary
      }}
    >
      {children}
    </BotContext.Provider>
  )
}

export default BotProvider
